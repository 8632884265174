import { QueryFunctionContext } from 'react-query';
import { ContentQueryDesignsService } from 'src/services/Designs';

export const QUERY_KEY = 'designs';

export const queryDesigns = (
    context: QueryFunctionContext<App.Designs.QueryKey>,
): Promise<Models.ContentQuery.Design> => {
    const { queryKey } = context;
    const [, id] = queryKey;

    return ContentQueryDesignsService.getDesign(id);
};
