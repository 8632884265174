import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';

class ContentQueryApi extends AbstractService implements Services.ContentQuery.Design {
    public async getDesign(
        id: string | undefined | false,
    ): Promise<Models.ContentQuery.Design> {
        const url = `api/v2/Designs/${id}`;

        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            return await this.api
                .get(url, {
                    searchParams,
                })
                .json<Models.ContentQuery.Design>();
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: `Failed to fetch Design: ${url}`,
                info: (e as Error).message,
                url,
            });
        }
    }
}

export const ContentQueryDesignsService = new ContentQueryApi(config.services.contentQueryApi);
