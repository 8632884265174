import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryDesigns, QUERY_KEY } from 'src/queries/queryDesigns';

type Design = Models.ContentQuery.Design;

export const useQueryDesign = (
    id: string,
    options?: UseQueryOptions<Design, Error, Design, App.Designs.QueryKey>,
): UseQueryResult<Design, Error> => useQuery<Design, Error, Design, App.Designs.QueryKey>(
    [QUERY_KEY, id],
    queryDesigns,
    {
        ...options,
    },
);
